define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "affiliates": "Partnerships & Affiliates",
    "affiliates.cliff-token": "Cliff Token",
    "affiliates.cliff-token.desc": "The deflationary dog token that never stops growing",
    "affiliates.dogecola": "DogeCola",
    "affiliates.dogecola.desc": "Crypto ecosystem and soft drink at the same time",
    "affiliates.flash-inu": "Flash Inu",
    "affiliates.flash-inu.desc": "NFT application suite for new and experienced collectors",
    "affiliates.flotillaprotocol": "FLOTILLA PROTO",
    "affiliates.flotillaprotocol.desc": "Be the whale you wish to see in the world",
    "affiliates.guzzler": "Guzzler",
    "affiliates.guzzler.desc": "The official creator of customizable and operable NFTs for your journey through the Metraverse",
    "affiliates.kawakami": "Kawakami",
    "affiliates.kawakami.desc": "The most powerful toolkit for meme tokens.",
    "affiliates.memekong": "MEME KONG",
    "affiliates.memekong.desc": "The kind of all MEME coins! Where the biggest apes eat!",
    "affiliates.midasdao": "MidasDAO",
    "affiliates.midasdao.desc": "Introduce DeFi X - automated treasury growth protocol...",
    "affiliates.neko": "Neko",
    "affiliates.neko.desc": "A hardware & software wallet co-developed with a leading blockchain security company",
    "affiliates.plant-dao": "Plant Dao",
    "affiliates.plant-dao.desc": "Plant trees, save the planet and earn rewards",
    "affiliates.pochi-inu": "Pochi Inu",
    "affiliates.pochi-inu.desc": "Pochi Inu, the unique Metaverse hybriding pet token",
    "affiliates.sins": "Sins",
    "affiliates.sins.desc": "The treasury backed wealth creation protocol",
    "affiliates.streamer-inu": "Streamer-Inu",
    "affiliates.streamer-inu.desc": "Connecting new channels and creating exposure for the best p2e games in web3.",
    "affiliates.the-science-dao": "The Science DAO",
    "affiliates.the-science-dao.desc": "A decentralized think tank, incubator engine, and accelerator platform.",
    "affiliates.wukong-project": "Wukong Project",
    "affiliates.wukong-project.desc": "One crypto project to create, revolutionize and share",
    "affiliates.zada-universe": "Zada Universe",
    "affiliates.zada-universe.desc": "A Hyper Reflection token that rewards its holders in a multitude of ways",
    "buy": "How to Buy",
    "buy.desc": "<p class=\"section-text\">NEKO currently runs only on the Ethereum Network, which means it can be best purchased and sold through <b>UniSwap</b></p><p class=\"section-text\">NEKO is a decentralized experiment project. As such we always recommend the use of DEXs. If you choose to utilize a CEX instead, remember to research it first to ensure it is both safe and secure.</p>",
    "buy.guide": "Quick Start Guide",
    "buy.guide.connectUniswap": "Connect your wallet to UniSwap",
    "buy.guide.connectUniswap.desc": "Connect your wallet to UniSwap by clicking ‘Connect wallet’ and selecting MetaMask",
    "buy.guide.createWallet": "Create MetaMask wallet",
    "buy.guide.createWallet.desc": "Create a MetaMask Wallet using either a desktop computer or an iOS/Android mobile device. That will allow you to buy, sell, send, and receive your $NEKO tokens",
    "buy.guide.sendEth": "Send ETH to your wallet",
    "buy.guide.sendEth.desc": "You can buy Ethereum (ETH) directly on MetaMask or transfer it to your MetaMask Wallet from exchanges like Bitmart, Ethereum, etc",
    "buy.guide.swap": "Swap ETH for NEKO",
    "buy.guide.swap.desc": "You can start swapping as soon as you have ETH available! Press ‘Select a token’ and enter the token address or search for it on the tokens list.",
    "cl.about": "About",
    "cl.buy": "Buy on Uniswap",
    "cl.buy.dextools": "Buy on Dextools",
    "cl.buy.uniswap": "Buy on UniSwap",
    "cl.buyNow": "Buy Now",
    "cl.chart": "Chart",
    "cl.connectWallet": "Connect Wallet",
    "cl.dextools": "Dextools",
    "cl.downloadWhitePaper": "WhitePaper",
    "cl.footer.links.ethereum": "Ethereum",
    "cl.footer.links.medium": "Medium",
    "cl.getStarted": "Get Started",
    "cl.howItWorks": "How it Works",
    "cl.howToBuy": "Buy $NEKO",
    "cl.integration": "Integration",
    "cl.loading": "Loading...",
    "cl.logoTitle": "<span>Maneki</span>-Neko",
    "cl.nekoGuard": "NekoGuard",
    "cl.nekoMask": "NekoMask",
    "cl.nftMint": "NFT Mint",
    "cl.roadmap": "Roadmap",
    "cl.staking": "Staking",
    "cl.theNeko": "The Neko",
    "cl.title": "Maneki-Neko - The Lucky Cat",
    "footer.about": "About",
    "footer.about.partnerships": "Partnerships & Affiliates",
    "footer.about.roadmap": "Roadmap",
    "footer.about.tokenomics": "Tokenomics",
    "footer.about.whitepaper": "Whitepaper",
    "footer.cookies": "Cookie Policy",
    "footer.copyright": "&copy; 2022 Neko All rights reserved.",
    "footer.newsletter": "Newsletter",
    "footer.privacy": "Privacy Policy",
    "footer.products": "Products",
    "footer.products.marketplace": "NFT Marketplace",
    "footer.products.neko": "The Neko",
    "footer.products.nekoguard": "NekoGuard",
    "footer.products.nekomask": "NekoMask",
    "footer.products.nekopay": "NekoPay",
    "footer.support": "Support",
    "footer.support.contact": "Contact Us",
    "footer.support.faq": "FAQ",
    "footer.terms": "Terms of Use",
    "home.about": "The Neko",
    "home.about.desc-bottom": "Buy - Store - Pay - Transfer - Manage - Grow",
    "home.about.desc-top": "All In One Hardware Wallet Solution for Individuals and Businesses Alike",
    "home.about.faq": "The Neko hardware wallet is a physical device where investors can safely store their cross-chain digital assets, including NFTs in a way that has not been experienced to date. The Neko Developers have dreamed and wished for every feature and convenience missing from today's hardware wallet technology. Securing their wish-list, The Neko Wallet will offer features such as:",
    "home.about.faq2": "The Neko Wallet brings true innovation through offering features not available in today's market. Safe, secure and with every convenience you could wish for, The Neko Wallet is tomorrow's technology, delivered today.",
    "home.banner.desc": "Providing safety, security and innovation for The Future of DeFi.",
    "home.banner.faq": "Neko offers a cross-chain digital asset storage device, a software wallet with an integrated NFT marketplace as well as an extensive project auditing service.",
    "home.banner.subtext": "The Future is Secure",
    "home.banner.text": "Maneki-<span>Neko</span>",
    "home.buy": "Buy Neko Wallet",
    "home.buyNeko": "Buy NEKO",
    "home.card.ease": "Easy To Use",
    "home.card.ease.desc": "A premium and slick touch screen experience. Just swipe and tap.",
    "home.card.offline": "100% Offline",
    "home.card.offline.desc": "The highest protection against online hackers. No WiFi, NFC, or USB.",
    "home.card.own": "Own Your Crypto",
    "home.card.own.desc": "Create your Private Key in a unique and patented offline interaction process.",
    "home.card.tokenomics.one": "4% goes towards the Liquidity Pool which helps strengthen the floor price over time.",
    "home.card.tokenomics.three": "2% goes towards further wallet Development costs and features for the future of DeFi.",
    "home.card.tokenomics.two": "9% will be used to market the hardware and software wallet. Neko will deploy a world-wide Marketing campaign as we move closer to releasing the full product.",
    "home.download": "Download NekoMask",
    "home.inquiry": "Inquire for Auditing",
    "home.learnMore": "Learn More",
    "home.liveChart": "Live Chart",
    "home.marketplace": "NFT Marketplace",
    "home.marketplace.desc": "A customized cross-chain NFT marketplace is integrated into the Neko wallet. Assets from all chains will be present and viewable within the “User Profile”. The Neko NFT marketplace eliminates the need for multiple wallets to store digital assets across various chains.",
    "home.nekoGuard": "NekoGuard",
    "home.nekoGuard.desc": "A comprehensive project auditing service, with a focus on security first. NekoGuard will have several layers including automated smart contract auditing, manual code reviews with dynamic testing by a team of DeFi security experts, and brand audits of DeFi projects.",
    "home.nekoMask": "NekoMask",
    "home.nekoMask.desc": "The Wallet Owned by All, Where the Transaction Fees Are Shared by the Stakers",
    "home.nekoMask.faq": "NekoMask is a software wallet that takes traditional and throws it out the window. Comparing to other software wallets is where the similarities end. The development team has integrated much needed updates and improvements to the wallet that we believe are essential to all investors. Some of these upgrades include:",
    "home.progress": "Neko development progress...",
    "home.roadmap": "Roadmap",
    "home.roadmap.desc": "View the journey so far, with separate timelines detailed for each Neko production",
    "home.techSupport": "TG: Tech Support",
    "home.tokenomics": "Tokenomics",
    "home.tokenomics.desc": "The Neko token incorporates a 0 tax feature on all purchases. On sells, there is a total of 15% tax with a breakdown as follows:",
    "neko.staking": "Stake <span>NEKO</span>",
    "neko.staking.approve": "Approve Tokens",
    "neko.staking.claiming": "Rewards",
    "neko.staking.connectWallet": "Connect Wallet",
    "neko.staking.eth.available": "Available ETH",
    "neko.staking.eth.claim": "Claim ETH",
    "neko.staking.help": "Problems staking NEKO?",
    "neko.staking.help.hint": "Checkout our FAQ's section for common questions and solutions",
    "neko.staking.nfts": "NFT's",
    "neko.staking.nfts.approve": "Approve NFT",
    "neko.staking.nfts.available": "Available NFT's",
    "neko.staking.nfts.boost": "NFT Booster",
    "neko.staking.nfts.boost.hint": "Stake a NEKO nft and multiply your ETH rewards",
    "neko.staking.nfts.stake": "Stake NFT",
    "neko.staking.nfts.stake.select": "Select an NFT to stake",
    "neko.staking.nfts.staked": "Staked NFT's",
    "neko.staking.nfts.stakedTime": "NFT {unlocked, plural, =1 {unlocked} other {will unlock}}",
    "neko.staking.nfts.unstake": "Unstake NFT",
    "neko.staking.rewards": "Receive ETH Rewards",
    "neko.staking.shares": "Your Shares",
    "neko.staking.shares.total": "Total Shares",
    "neko.staking.stake": "Stake Tokens",
    "neko.staking.stake.max": "MAX",
    "neko.staking.staking": "Stake",
    "neko.staking.tokensAvailable": "Tokens Available",
    "neko.staking.tokensAvailable.value": "{number} NEKO",
    "neko.staking.unlock": "Unlock Tokens",
    "neko.staking.unlock.period": "Shares {unlocked, plural, =1 {were unlocked} other {will unlock}}",
    "neko.staking.unstake": "Unstake Tokens",
    "neko.staking.unstaking": "Unstake",
    "nekoMask.beta.apply": "Apply Today",
    "nekoMask.beta.hint": "Beta testing for the NekoMask is available to Neko Token/NFT holders. Register today and be amongst the elite few to be the first to experience the innovative changes in the DeFi space.",
    "tokenomics": "Tokenomics",
    "tokenomics.buy-tax": "Buy Taxes",
    "tokenomics.max-wallet": "Max Wallet",
    "tokenomics.sell-tax": "Sell Taxes",
    "tokenomics.total-supply": "Total Supply"
  }]];
  _exports.default = _default;
});